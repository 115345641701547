<template>
  <b-card v-if="this.permission.view">
    <table-list @detailId="goToDetail"></table-list>
  </b-card>
</template>
<script>
import { environment } from '@/environments/environment'
import TableList from './component/TableList.vue'
import BaseServices from '@/store/services/index'
export default {
  components: { TableList},
  data() {
    return {
      baseService: new BaseServices(this),
      permission: {
        view: false
      },
      checkPermission: 0
    }
  },
  mounted() {
    this.setDataPermissions()
  },
  methods: {
    goToDetail(id) {
      this.m_openWinBlank(`/config-carriers/${id}`)
    },
    setDataPermissions() {
      const permissionArray = this.$session.get('cas_user')?.special_permissions ?? []
      const viewPermissions = ['view-carrier-configs', 'edit-carrier-configs']
      const isAdmin = this.$session.get('cas_user').role === 'admin'
      const checkPermissionView = !!permissionArray.find((element) => viewPermissions.includes(element)) || isAdmin
      if (checkPermissionView) {
        this.permission.view = true
      } else if (this.checkPermission < 5) {
        this.checkPermission += 1
        location.reload()
      }
    }
  }
}
</script>
<style lang="scss" >
  .input--class--component {
    padding-right: 0;

    .form-control{
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: 0;
      padding: 0 1rem;
      height: 2.67rem;
    }
  }
  .select--class--component {
    padding-left: 0;

    .vs__dropdown-toggle {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      text-align: center;
    }

    .v-select.vs--single .vs__selected {
      margin-top: 0;
    }
  }
  .collapse--space {
    visibility: collapse;
  }
</style> 