<template>
  <b-modal id="modalMassiveConfig" title="Carga Masiva" size="sm" centered text ok-only hide-footer @show="clearElements">
    <form-render :form.sync="form" :fields="fieldsForm" ref="formRenderMassiveCarriers" @send="sendMassive">
      <template #fileConfigSlot>
        <b-container>
          <b-row><span>{{ $t('Archivo de importación') }}</span><small><a href="https://storage.googleapis.com/tickets-misc/importacion_configuracion_de_carriers_new.xlsx"> (Descargar formato)</a></small></b-row>
          <b-row>
            <b-form-file :disabled="loading.updatingData" v-model="form.attachmentFile" :state="Boolean(form.attachmentFile)" name="file" accept=".xlsx, .xls" class="cursor-pointer"
            :placeholder="$t('Elige un archivo...')" 
            :browse-text="$t('Buscar')" 
            />
          </b-row>
        </b-container>
      </template>
      <template #buttonsGroupSlot>
        <b-alert v-model="errorUpdate" v-height-fade.appear variant="danger" class="w-100">
          <div class="alert-body">
            {{ $t('Error al subir archivo') }}
          </div>
        </b-alert>
        <div class="w-100">
          <b-button v-if="errorUpdate" v-b-toggle.collapse-1 variant="gradient-secondary">
            {{ $t('Descripción') }}
          </b-button>
          <b-button class="float-right" :disabled="loading.updatingData" variant="warning" @click="uploadAttachment">
            <template v-if="loading.updatingData">
              <b-spinner small  /> {{ $t('Cargando Archivo') }}
            </template>
            <template v-else>
              {{ $t('Cargar Archivo') }}
            </template>
          </b-button>
        </div>
        <b-collapse id="collapse-1" class="mt-2 w-100">
          <div class="container--alert">
            <b-alert variant="dark" show>
              <h4 class="alert-heading">
                {{ $t('Listado de errores') }}
              </h4>
              <div class="alert-body" v-for="(errorItem, index) in dataErrorsFile" :key="index">
                <p>
                  <b-badge variant="light-danger">
                    {{errorItem.row}}
                  </b-badge> {{errorItem.error}}
                </p>
              </div>
            </b-alert>
          </div>
        </b-collapse>
      </template>
    </form-render>
  </b-modal>
</template>
<script>
import {
  BContainer,
  BRow,
  BCollapse,
  VBToggle
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import BaseServices from '@/store/services/index'
export default {
  components: {
    BContainer,
    BRow,
    BCollapse
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple
  },
  data() {
    return {
      form: {},
      fieldsForm: [],
      errorUpdate: false,
      loading: {
        updatingData: false
      },
      dataErrorsFile: [],
      baseService: new BaseServices(this)
    }
  },
  mounted() {
    this.setInitialData()
  },
  methods: {
    close() {
      this.$bvModal.hide('modalMassiveConfig')
    },
    clearElements() {
      this.form = {}
    },
    setInitialData() {
      this.fieldsForm = [
        {fieldType: 'FieldInput', name: 'email_notification', label: 'Correo de notificación', containerClass: 'col-12 container-info', validation: 'required'},
        {name: 'fileConfigSlot', useSlot:true, containerClass: 'col-12 container-info'},
        {name: 'buttonsGroupSlot', useSlot:true, containerClass: 'col-12'}
      ]
    },
    uploadAttachment() {
      // bvModalEvent.preventDefault()
      this.$refs.formRenderMassiveCarriers.checkForm()
    },
    sendMassive() {
      this.loading.updatingData = true
      if (this.form && this.form.attachmentFile) {
        const params = {
          shipper_id: this.$session.get('cas_user').id ? this.$session.get('cas_user').id : ''
        }
        const queryParams = {
          email_notification: this.form.email_notification
        }
        this.baseService.callSpecialUploadFile('postFileMassiveCarriers', this.form.attachmentFile, params, this.$session.get('cas_user'), queryParams)
          .then(response => {
            this.errorUpdate = false
            this.dataErrorsFile = []
            this.$success(response.message)
            this.close()
          })
          .catch(err => {
            this.errorUpdate = true
            this.dataErrorsFile = []
            if (Array.isArray(err.errors)) this.dataErrorsFile = [...err.errors]
          }).finally(() => {
            this.loading.updatingData = false
          })
      } else {
        this.$alert(this.$t('msg-problema-cargar-datos', {
          code: 'Debe adjuntar un archivo'
        }))
        this.loading.updatingData = false
      }
    } 
  }
}
</script>