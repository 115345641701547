<template>
  <div>
    <filter-swapper class="mb-1" :controlHeightButtons="controlHeight">
      <template #slot1>
        <form-render :form.sync="form" :fields="fieldsForm">
          <template #buttonsGroupSlot>
            <b-row align-v="center">
              <b-col cols="12" class="mt-2 container--button-carriers">
                <b-button @click="getCarrierFilterButton"
                  class="btn-check "
                  variant="outline-warning"
                  :title="$t('Buscar')">
                  <b-spinner small v-if="!loading.carriersList"/>
                  Buscar
                </b-button>
                <b-button variant="outline-success" v-b-modal.modalMassiveConfig>Carga Masiva</b-button>
              </b-col>
            </b-row>
          </template>
        </form-render>
      </template>
    </filter-swapper>
    <div v-show="loading.carriersList" class="table--container">
      <table-render id="table-list-carriers" class="text-center" :schema="schema" :actions="actions" :rows="rows" :loading="!loading.carriersList"></table-render>
      <pagination :pagination="pagination" :noDigits="pagination.noShowDigits" :showSize="true"/>
    </div>
    <div class="table-render-skeleton" v-if="!loading.carriersList">
      <b-skeleton-table
        :rows="pagination.limit || 10"
        :columns="schema.length || 10"
        :table-props="{ }"/>
    </div>
    <modal-massive-config></modal-massive-config>
  </div>
</template>
<script>
import BaseServices from '@/store/services/index'
import ModalMassiveConfig from './ModalMassiveConfig.vue'
export default {
  name: 'table-list-carrier',
  components: {
    ModalMassiveConfig
  },
  props: [],
  data() {
    return {
      baseService: new BaseServices(this),
      loading: {
        carriersList: false
      },
      pagination: {
        page: 1,
        total: 0,
        limit: 10,
        noShowDigits: false,
        calculateCountPage: true
      },
      carrierCode: '',
      serviceCode: '',
      contractType: '',
      countryCode: '',
      schema: [],
      actions: [],
      rows: [],
      countries: [],
      fieldsForm: [
        {fieldType: 'FieldInput', name: 'carrier_code', label: 'Código Courier', containerClass: 'col-12 col-md-4 container-info'},
        {name: 'buttonsGroupSlot', useSlot:true, containerClass: 'col-12 col-md-4'}
      ],
      form: {},
      controlHeight: { class: 'row mb-12 spacing-label-field'},
      detailConfig: {}
    }
  },
  mounted() {
    this.getAllInitialData()
  },
  watch: {
    'pagination.page'() { 
      this.changePage()
    },
    'pagination.limit'() {
      this.changePage()
    }
  },
  methods: {
    changePage() {
      this.getCarrierFilter()
    },
    getAllInitialData() {
      this.loading.carriersList = false
      const queryParams = this.setQueryParams()
      const params = {
        shipper_id: this.$session.get('cas_user').id ? this.$session.get('cas_user').id : ''
      }
      const arrServices = [
        {
          name: 'getCarriersList',
          queryParams: {
            ...queryParams
          },
          params: {
            ...params
          }
        },
        {
          name: 'getCountries'
        }]
      this.baseService
        .callMultipleServices(arrServices, true)
        .then((response) => {
          this.rows = [...response.getCarriersList.data]
          this.pagination.total = response.getCarriersList.pagination.Total
          this.countries = [...response.getCountries.data]
          this.countries = this.countries.map((el) => ({
            ...el,
            text: el.name,
            color: 'black'
          }))
        })
        .catch((err) => {
          console.error(err)
          this.$alert(this.$t('msg-problema-cargar-datos', {
            code: err
          }))
        })
        .finally(() => {
          this.loading.carriersList = true
          this.setInitialData()
        })
    },
    getCarrierFilterButton() {
      this.getByFilter = true
      if (this.pagination.page === 1) {
        this.getCarrierFilter()
      } else {
        this.pagination.page = 1
      }
    },
    getCarrierFilter() {
      this.loading.carriersList = false
      const queryParams = this.setQueryParams()
      const params = {
        shipper_id: this.$session.get('cas_user').id ? this.$session.get('cas_user').id : ''
      }
      this.baseService.callService('getCarriersList', queryParams, params)
        .then(response => {
          if (response) {
            this.rows = []
            this.rows = [...response.data]
            if (this.getByFilter) {
              this.pagination.total = response.pagination.Total
            }
          }
        })
        .catch(err => {
          this.$alert(this.$t('msg-problema-cargar-datos', {code: err}))
        })
        .finally(() => {
          this.getByFilter = false
          this.loading.carriersList = true
        })
    },
    setInitialData() {
      this.fieldsForm.unshift(
        {fieldType: 'FieldSelect', name: 'country', label: 'País', containerClass: 'col-12 col-md-4 container-info', options: this.countries, addFlags: true, searchOnType: { nChars: 999}}
      )
      this.schema = [
        {label: 'País', key: 'country_name'},
        {label: 'Nombre', key: 'carrier_name'},
        {label: 'Código Courier', key: 'carrier_code'},
        {label: 'Código Envíame', key: 'enviame_code'},
        {label: 'Categoría Servicio', key: 'carrier_service_type_code'},
        {label: 'Tipo Negocio', key: 'contract_type_code'},
        {label: 'Activado', key: 'state'},
        {label: 'Courier Manager', key: 'carrier_manager'},
        {label: 'Acciones', key: 'actions', class: ['text-center thead--light']}
      ]
      this.actions = [
        {action: id => this.$emit('detailId', id), icon: 'FileTextIcon', color:'warning', text: 'Abrir detallado carrier'}
      ]
    },
    setQueryParams () {
      const response = {}
      if (this.pagination.page) response.page = this.pagination.page
      if (this.pagination.limit) response.limit = this.pagination.limit
      if (this.form.carrier_code) response.carrier_code = this.form.carrier_code
      if (this.form.country) response.country_code = this.form.country.code
      if (this.serviceCode) response.service_code = this.serviceCode
      if (this.contractType) response.contract_type = this.contractType
      return response
    } 
  }
}
</script>
<style lang="scss" scoped>
.container--button-carriers {
  display: flex;
  gap: 1rem;
}
  
</style>